@use 'transactions';

/* App-scoped styles (everything behind sign in) */
html[data-style-scope='app'] {
  /* 
   * Start with the high-level spacing of the main app sections.
   */
  #root {
    max-width: 800px;
    margin: 0 auto;
    padding: calc(1em - 2px) 1em 1em;
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr; /* header, main... */
  }
  #root > header {
    margin-bottom: 1.4em;
  }

  #loader {
    position: fixed;
    top: env(safe-area-inset-top);
    left: 0;
    right: 0;
    z-index: 1000;
  }

  /* 
   * Nav
   */
  nav {
    width: 100%;
    display: flex;
    justify-content: right;
    gap: 1em;
  }
  nav a {
    padding: 0.1em;
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all 200ms;
  }
  nav a::after {
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.3em;
    border-radius: 6px;
    transition: all 200ms;
  }
  nav a.active:after {
    background-color: var(--bs-body-color);
  }
  nav a:not(.active) span:first-of-type {
    color: var(--bs-primary);
  }
  nav a.active span:first-of-type {
    color: var(--bs-body-color);
  }
  nav a > svg {
    color: var(--bs-primary);
    margin-right: 8px;
    width: 20px;
    height: 20px;
    transition: all 200ms;
  }
  nav a:hover:not(.active)::after {
    background-color: var(--bs-secondary);
  }
  nav a.active > svg {
    color: var(--bs-body-color);
  }

  .dashboard-gauge-container {
    position: sticky;
    top: 0;
    width: 100%;
    background: var(--bs-body-bg);
    padding: 0.8em 0;
    margin-top: -0.8em;
    border-bottom: 1px solid var(--bs-border-color);
    z-index: 1;
  }

  .dashboard-footer {
    position: sticky;
    bottom: 8px;
    margin-top: 10px;
  }

  .budget-button {
    display: flex;
    padding: 1em;
    justify-content: space-between;
    width: 100%;
    border: 0;
  }
  @media (prefers-color-scheme: dark) {
    .budget-button {
      background: var(--bs-dark-bg-subtle);
      &:hover {
        background: var(--bs-secondary-bg-subtle);
      }
    }
  }

  /* 
   * Bootstrap overrides — careful!
   */
  .progress-bar {
    transition: all 300ms;
  }
  .reverse-full-width-switch .form-switch label {
    width: 100%;
  }
  .reverse-full-width-switch .form-check-reverse label {
    text-align: left;
  }

  .list-item-with-border {
    border-top: 1px solid transparent; // Added to ensure padding is visually consistent (not off by 1px due to the bottom border
    border-bottom: 1px solid var(--bs-border-color);
    &:first-child {
      border-top: 0;
      padding-top: 0 !important;
    }
    &:last-child {
      padding-bottom: 0 !important;
      border-bottom: 0;
    }
  }

  @media (max-width: 576px) {
    nav {
      justify-content: space-between;
      gap: 0;
    }
  }
}
